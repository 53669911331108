/* tslint:disable */
/* eslint-disable */
/**
 * proto/server.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * TopJokesEntry contains the rank and text of the joke.
 * @export
 * @interface V1TopJokesEntry
 */
export interface V1TopJokesEntry {
    /**
     * Rank of the joke.
     * @type {string}
     * @memberof V1TopJokesEntry
     */
    rank?: string;
    /**
     * Text of the joke.
     * 
     * TODO(rbtz): add model name?
     * @type {string}
     * @memberof V1TopJokesEntry
     */
    text?: string;
}

/**
 * Check if a given object implements the V1TopJokesEntry interface.
 */
export function instanceOfV1TopJokesEntry(value: object): value is V1TopJokesEntry {
    return true;
}

export function V1TopJokesEntryFromJSON(json: any): V1TopJokesEntry {
    return V1TopJokesEntryFromJSONTyped(json, false);
}

export function V1TopJokesEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1TopJokesEntry {
    if (json == null) {
        return json;
    }
    return {
        
        'rank': json['rank'] == null ? undefined : json['rank'],
        'text': json['text'] == null ? undefined : json['text'],
    };
}

export function V1TopJokesEntryToJSON(value?: V1TopJokesEntry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rank': value['rank'],
        'text': value['text'],
    };
}

