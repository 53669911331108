/* tslint:disable */
/* eslint-disable */
/**
 * proto/server.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1Winner } from './V1Winner';
import {
    V1WinnerFromJSON,
    V1WinnerFromJSONTyped,
    V1WinnerToJSON,
} from './V1Winner';

/**
 * RateChoicesRequest to rate the presented jokes.
 * @export
 * @interface ArenaRateChoicesBody
 */
export interface ArenaRateChoicesBody {
    /**
     * 
     * @type {V1Winner}
     * @memberof ArenaRateChoicesBody
     */
    winner?: V1Winner;
    /**
     * 
     * @type {V1Winner}
     * @memberof ArenaRateChoicesBody
     */
    known?: V1Winner;
}



/**
 * Check if a given object implements the ArenaRateChoicesBody interface.
 */
export function instanceOfArenaRateChoicesBody(value: object): value is ArenaRateChoicesBody {
    return true;
}

export function ArenaRateChoicesBodyFromJSON(json: any): ArenaRateChoicesBody {
    return ArenaRateChoicesBodyFromJSONTyped(json, false);
}

export function ArenaRateChoicesBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArenaRateChoicesBody {
    if (json == null) {
        return json;
    }
    return {
        
        'winner': json['winner'] == null ? undefined : V1WinnerFromJSON(json['winner']),
        'known': json['known'] == null ? undefined : V1WinnerFromJSON(json['known']),
    };
}

export function ArenaRateChoicesBodyToJSON(value?: ArenaRateChoicesBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'winner': V1WinnerToJSON(value['winner']),
        'known': V1WinnerToJSON(value['known']),
    };
}

